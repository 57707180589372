.navbar-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1000;
  box-sizing: border-box;
  width: 100%;
}

.navbar-root {
  padding: 0rem 2rem;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  backdrop-filter: blur(1rem);
  height: 4rem;
  position: relative;
  box-sizing: border-box;
}

.navbar-root.scrolled {
  background-color: #101628; /* Change background color after scrolling */
}

.left-side-navlinks,
.right-side-navlinks {
  padding-left: 0;
  display: flex;
  align-items: center;
  gap: 1.35rem;
  list-style: none;
  font-size: 0.8rem;
}

.left-side-navlinks {
  position: relative;
}

.login-btn {
  text-decoration: none;
  color: rgba(10, 29, 47, 1);
  /* background: linear-gradient(
    180deg,
    rgba(255, 87, 3, 1),
    rgba(255, 200, 2, 1)
  ); */
  /* width: 50px;*/
  background: linear-gradient(0, #ffaa02, #ff4300);
  height: 5px;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  border: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

li a {
  text-decoration: none;
  color: #ffffff;
}

.activeLink {
  border-bottom: 2px solid #ff6b00;
}

.icon {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  background-color: black;
  margin: 0;
  padding: 0;
}

.towno-logo {
  position: absolute;
  left: 50%;
  top: 2rem;
  transform: translate(-50%, -50%);
}

.link-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@keyframes expand {
  0% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes collapse {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 0);
  }
}
.side-navbar-links {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  list-style: none;
  font-size: clamp(1.3rem, 4vw, 1.8rem);
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  width: 100%;
}

.dropdown-icon {
  rotate: 90deg;
  transition: rotate 0.3s;
  font-size: 1rem;
}

.scroll-watcher {
  height: 0.2rem;
  background: linear-gradient(45deg, #f00 -12.04%, #fff300 161.11%);
  width: 100%;
  scale: 0 1;
  animation: scroll-tracker linear;
  animation-timeline: scroll();
  transform-origin: left;
}

@keyframes scroll-tracker {
  to {
    scale: 1 1;
  }
}

.nav-item {
  display: flex;
  gap: 0.45rem;
  align-items: center;
  position: relative;
}

.destination-category-title,
.expand-sub-category {
  font-weight: 600;
  color: #000 !important;
}

.expand-sub-category {
  transition: all 1s;
}

.destination-category-title-sub-title {
  list-style: none;
  padding-left: 1rem;
  font-size: 0.9rem;
}

.expand {
  transform: rotate(90deg);
  transition: transform 1s;
}

.destination-category-title:hover,
.destination-category-title-sub-title:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #f46d25 !important;
}

@media screen and (max-width: 768px) {
  .link-container {
    flex-direction: column;
    box-sizing: border-box;
    padding: 0rem 0rem 1rem 0rem;
    justify-content: flex-start;
    position: absolute;
    top: 4rem;
    left: 0;
    width: 100%;
    background: rgba(13, 18, 28, 1) !important;
    backdrop-filter: blur(7rem) !important;
    z-index: 10;
    transform-origin: top;
    align-items: flex-start;
  }

  [data-sideNav="true"].link-container {
    display: flex;
    animation-name: expand;
    animation-duration: 250ms;
    animation-fill-mode: forwards;
    height: 100vh;
  }

  [data-sideNav="false"].link-container {
    animation-name: expand;
    animation-duration: 2s;
    animation-direction: reverse;
    animation-fill-mode: backwards;
    visibility: hidden;
  }

  .navbar-root {
    margin-top: -0.6rem;
    padding: 0rem 1rem;
    /* z-index: 9999; */
    align-items: flex-start;
  }
  .towno-logo {
    position: relative;
    width: 6rem;
    /* top: 1rem; */
    transform: translate(-50%, -50%);
  }
  .scroll-watcher {
    display: none;
  }

  .dropdown-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .dropdown-icon {
    font-size: 1.5rem;
  }

  .activelink {
    border: none;
    color: #f46d25;
  }

  .dropdown-title {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .destination-dropdown-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .dropdown-container.expand {
    top: 2.2rem;
    padding: 0rem;
    border: none;
    padding: 0.5rem;

    width: 100%;
    background: #f2f2f2;
  }

  .destination-location-list-category-wise {
    display: flex;
    flex-direction: column;
    /* background-color: #f00; */
    gap: 0rem;
  }

  .collapse-category {
    display: none;
  }

  .expand-category {
    display: flex;
    flex-direction: column;
  }
  .login-btn {
    width: 100%;
    padding: 1.5rem;
  }
}

@keyframes slide {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

/*dropdown-styling*/
.expand-icon {
  rotate: 90deg;
  transition: all 0.25s;
}

.rotate {
  transform: rotate(180deg);
  transition: transform 0.25s;
}

.destination-dropdown {
  width: fit-content;
  top: 2.75rem;
  background: #fff;
  backdrop-filter: blur(10rem) !important;
  border: 2px solid #f46d25;
  border-radius: 4px;
  list-style: none;
  color: #000;
  padding: 1rem 0.5rem;
  font-size: 1rem;
}

.absolute {
  position: absolute;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

@media screen and (max-width: 768px) {
  .destination-dropdown {
    width: 100%;
    font-size: 1.3rem;
    background: transparent;
  }
  .destination-category-title,
  .expand-sub-category {
    color: #f46d25 !important;
  }

  .title-container {
    justify-content: space-between;
  }

  .destination-category-title-sub-title {
    color: #f46d25 !important;
    font-size: 1.2rem;
  }

  .expand-sub-category {
    flex: 1 1 auto;
    /* border: 1px solid red !important; */
    width: 100%;
    border-radius: 0rem !important;
    height: 100% !important;
  }
}
