.navbars {
  width: 100%;
  height: 70px;
  background: #000;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;

  .logo {
    padding-left: 15px;

    img {
      width: 180px;
      vertical-align: middle;
    }
  }
}
