.admin {
  padding: 0px 0px;
  width: 150px;
}
.admin .tippy-content {
  padding: 0px;
}
.navbars {
  width: 100%;
  height: 70px;
  background: #000;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
}

.navbars .logo {
  padding-left: 30px;
}

.logo img {
  width: 180px;
  vertical-align: middle;
}

.subHeader {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
  padding-right: 7px;
}

.more {
  color: #fff;
  display: flex;
  align-items: center;
  margin: 0 15px;
  cursor: pointer;
}

.more .item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  font-size: 12px;
}

.more .item .caption {
  display: block;
  margin-top: -2px;
}
