@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

.welcome-text-island-illustrator-container {
  position: relative;
  /* margin-top: -3rem; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.island-illustration {
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  aspect-ratio: 16/6;
}

.welcome-message-container {
  /* top: clamp(180px, 10vw, 300px); */
  position: absolute;
  padding: 0rem 2rem;
  /* box-sizing: border-box; */
  display: flex;
  flex-direction: column;
  /* background: red; */
}

.welcome-primary-text {
  font-size: clamp(1.85rem, 7vw, 4.2rem);
  font-weight: 800;
}

.where-text {
  color: white;
}

.where-text span {
  /* background: linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1)); */
  background: linear-gradient(180deg, #ff4300, #ffaa02);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.welcome-message-container p {
  color: white;
  font-size: clamp(1rem, 3vw, 1.6rem);
  font-weight: 500;
  line-height: 50px;
  margin-top: -0.3rem;
  padding: 0rem 0.5rem;
}

.search-option {
  display: flex;
  gap: 10px;
}

.hotel-btn.active-btn,
.package-btn.active-btn {
  cursor: pointer;
  font-size: 20px;
  height: 40px;
  width: 133px;
  text-decoration: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  border: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(
    to left,
    rgba(255, 87, 3, 1),
    rgba(255, 189, 2, 1),
    rgba(255, 200, 2, 1)
  ); */
  /* ------above is previous linear grdient colour -------- */
  background: linear-gradient(0, #ffaa02, #ff4300);
  color: rgba(10, 29, 47, 1);
  transform: scale(1);
  transition: background transform 1s;
  /* box-shadow: 0rem 0rem 0.5rem #f46d25; */
}

@keyframes activeAnimation {
  from {
    background: rgba(255, 255, 255, 1);
    color: rgba(92, 92, 92, 1);
  }
  to {
    /* background: linear-gradient(
      to left,
      rgba(255, 87, 3, 1),
      rgba(255, 189, 2, 1),
      rgba(255, 200, 2, 1)
    ); */
    background: linear-gradient(0, #ffaa02, #ff4300);
    color: rgba(10, 29, 47, 1);
  }
}

.hotel-btn,
.package-btn {
  cursor: pointer;
  font-size: 20px;
  height: 40px;
  width: 133px;
  text-decoration: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  border: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  color: rgba(92, 92, 92, 1);
  transform: scale(1);
  transition: all;

  /* animation: activeAnimation 0.5s reverse; */
}

.search-fields {
  margin-top: 1rem;
  padding: 10px 0.6rem;
  /* background: rgba(255, 255, 255, 1); */
  /* border: 1px solid rgba(232, 235, 247, 1); */
  border-radius: 4px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.8rem;
  font-family: "Poppins", sans-serif;
  backdrop-filter: blur(7rem);
  box-shadow: 0 0 1rem #000000;
}

.search-fields button {
  border: none;
  /* background: linear-gradient(
    to right,
    rgba(255, 243, 0, 1),
    rgba(255, 67, 0, 1)
  ); */
  background: linear-gradient(0, #ffaa02, #ff4300);
  /* color: white; */
  color: rgba(10, 29, 47, 1);
  padding: 0.3rem 1.8rem;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  flex: 1 1 2rem;
}

.search-fields input::placeholder {
  color: rgba(39, 62, 71, 1);
  font-size: 0.8rem;
  text-align: center;
}

.search-fields input {
  border: none;
  background: rgba(232, 235, 247, 1);
  border-radius: 4px;
  flex: 1 1 3rem;
  padding: 0.2rem 0.3rem;
  height: 2rem;
}

.search-fields input:focus-within {
  border: 1px solid #f46d25;
  padding: 0.2rem 0.3rem;
  height: 2rem;
  outline: none;
  box-sizing: border-box;
}

.offer-banner-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
}
.offer-banner-background-img {
  width: 100%;
  /* height: 100vh; */
}

/* .offer-image-context {
  position: absolute;
  top: 200px;
} */

.offer-body-container {
  top: 0;
  position: absolute;
  display: flex;
  /* background: linear-gradient(90deg, #000 -27.69%, rgba(0, 0, 0, 0) 185.15%); */
  background: linear-gradient(90deg, #0f1d2e -10.69%, rgba(0, 0, 0, 0) 110.15%);
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 2rem;
  box-sizing: border-box;
  /* height: 100vh; */
}
.banner-primary-text {
  color: white;
  font-size: 2.5rem;
  font-weight: 800;
  top: 10px;
}
.banner-primary-text p {
  padding: 0rem;
  margin: 0rem;
  font-size: 2.5rem;
}

.banner-primary-text .home-valid-off {
  /* background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1)); */
  background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* visibility: hidden;
  opacity: 0;
  transform: translateY(100px);
  transition: transform 1s, opacity 1s; */
}

.show-offer {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.banner-primary-text .banner-secondary-text {
  font-size: 1.2rem;
  /* width: min(900px, calc(40% + 100px)); */
  width: min(900px, calc(32% + 100px));
  padding: 1rem 0rem;
  font-weight: 100;
}

.offer-btn {
  border: none;
  outline: none;
  /* background: linear-gradient(60deg, rgba(255, 243, 0, 1), rgba(255, 0, 0, 1)); */
  background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
  font-weight: bold;
  padding: 0.3rem 1.5rem;
  font-size: 20px;
  border-radius: 4px;
  transition: 0.5s;
  width: fit-content;
}

.offer-btn:hover {
  /* transform: scale(1.1);
  background: linear-gradient(60deg, rgba(255, 0, 0, 1), rgba(255, 243, 0, 1)); 
  transition: background 1s all; */
  cursor: pointer;
}

.about-towno {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 1rem 2rem;
  flex-wrap: wrap;
}

.about-header {
  background: rgba(255, 236, 225, 1);
  color: #f46d25;
  /* width: 252px; */
  width: 17%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-size: 14px;
  border-radius: 2.2rem;
}

.about-text-container {
  flex: 1 1 300px;
}

.about-us-primary-text {
  color: rgba(255, 255, 255, 1);
  font-size: clamp(2rem, 7vw, 4rem);
  text-wrap: balance;
  padding: 1rem 0rem;
}

.about-us-primary-text .boldtext {
  font-weight: bolder;
  background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-us-secondary-text {
  color: rgba(94, 98, 130, 1);
  width: 80%;
  text-wrap: balance;
  font-size: clamp(0.5rem, 4vw, 1rem);
}

.explore-illustration {
  flex: 1 1 200px;
  width: clamp(200px, 10vw, 500px);
}

.contact-us {
  border: none;
  outline: none;
  /* background: linear-gradient(60deg, rgba(255, 243, 0, 1), rgba(255, 67, 0, 1)); */
  background: linear-gradient(0, #ffaa02, #ff4300);
  font-weight: bold;
  border-radius: 4px;
  padding: 0.6rem 1.5rem;
  font-size: 1.2rem;
  transition: 0.5s;
  margin: 1rem 0rem;
  cursor: pointer;
}

.contact-us:hover {
  /* transform: scale(1.1);
  transition: 0.5s; */
  cursor: pointer;
}

.home-blog-section {
  padding: 3rem 2rem;
}

.home-blog-section .blog-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.home-blog-section .blog-title div {
  font-size: clamp(2rem, 10vw, 3.6rem);
  color: rgba(255, 255, 255, 1);
  font-weight: 800;
}

.home-blog-section .blog-title div span {
  background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-blog-section .blog-title .blog-sub-title {
  font-size: clamp(0.5rem, 10vw, 1rem);
  color: rgba(255, 255, 255, 1);
  margin-top: 1rem;
}

@media screen and (max-width: 786px) {
  .island-illustration {
    height: 100vh;
  }

  .welcome-message-container {
    padding: 0rem 2rem 0rem 1rem;
  }

  .offer-banner-background-img {
    height: 100vh;
  }
}
