.cardContainer {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  gap: 1.5rem;
  align-items: start;
  transform: translateX(0);
  transition: transform 0.5s;
}

.cardContainer:hover {
  transform: translateX(10px);
  cursor: pointer;
}

.small {
  width: 20%;
  aspect-ratio: 1/0.6;
  border-radius: 0.3rem;
  border: 4px;
}

.medium {
  width: 20rem;
  aspect-ratio: 2/1;
  border: 4px;
}

.content {
  color: rgba(181, 181, 181, 1);
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
}

.title {
  color: rgba(244, 109, 37, 1);
  margin: 0;
}

@media (max-width: 768px) {
  .cardContainer {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    gap: 1.5rem;
    align-items: start;
    transform: translateX(0);
    transition: transform 0.5s;
    flex-direction: column;
  }

  .small,
  .medium {
    width: 100%;
    aspect-ratio: 1/0.7;
  }

  .content {
    text-align: justify;
  }
}
