.key-points-section {
  padding: 3rem 2rem;
}

.key-points-container {
  padding: 2rem;
  display: flex;
  gap: 2rem;
  background: rgba(255, 239, 231, 1);
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .key-points-container {
    flex-wrap: wrap;
    padding: 2rem 1rem;
  }
  .key-points-section {
    padding: 3rem 1rem;
  }
}
