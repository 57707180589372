.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2rem;
  box-sizing: border-box;
  top: 0;
  z-index: 1000;
  /* background: linear-gradient(
    180deg,
    rgba(194, 138, 7, 1),
    rgba(254, 88, 0, 1)
  ); */
  background: linear-gradient(180deg, #ff4300, #ffaa02);
  height: 2rem;
  margin: 0;

  width: 100%;
}

.header .query-links {
  display: flex;
  gap: 20px;
  color: white;
}
.header .header-icons {
  padding-left: 0;
  display: flex;
  gap: 20px;
  color: white;
  list-style: none;

  align-items: center;
}

.header-icons :is(img) {
  cursor: pointer;
}

.media-icons {
  fill: white;
  cursor: pointer;
  transform: translateY(0);
  transition: all 0.5s;
}

.media-icons:hover {
  transform: translateY(-20%);
  transition: 0.5s all;
}

@media (max-width: 768px) {
  .header-icons :is(img) {
    width: 0.9rem;
  }

  .header .header-icons {
    gap: 1rem;
  }

  .header .query-links {
    font-size: 0.8rem;
  }

  .header {
    padding: 1rem 1rem 1.5rem 1rem;
  }
}
