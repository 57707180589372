.keyPoint {
  flex: 1 1 300px;
  display: flex;
  gap: 1rem;
}

.imgContainer {
  flex: 1 1 4.625rem;
  /* background: rgba(255, 255, 255, 1); */
  border-radius: 3px;
  max-width: 4.625rem;
  height: 5rem;
}

.textContainer {
  flex: 1 1 230px;
}

.header {
  color: rgba(244, 109, 37, 1);
  font-size: clamp(0.8rem, 10vw, 1.3rem);
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.content {
  color: rgba(94, 98, 130, 1);
  font-size: clamp(0.3rem, 10vw, 0.8rem);
  box-sizing: border-box;
  text-align: justify;
}
