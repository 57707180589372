:root {
  --footer-border-radius: 0rem 0rem 0.2rem 0.2rem;
  --page-padding: 4rem 2rem;
}

.destinationPageContainer {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding: var(--page-padding);
  background: rgba(13, 18, 28, 1);
}

.contentWithCarousel {
  /* padding: 2rem 8rem 4rem 8rem; */

  display: flex;
  box-sizing: border-box;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}
/* .contentCard {
  flex: 1 1 200px;
} */

.sliderContainer {
  width: 30%;
}

.slideImageContainer {
  width: 100%;
}

.slideImageContainer img {
  width: 100%;
  aspect-ratio: 1/0.7;
  object-fit: cover;
}

.slider ul li button:before {
  color: #fff;
  opacity: 1;
}

.slider > ul > li.slick-active > button::before {
  color: #f46d25;
}

.contentCard div {
  color: rgba(150, 150, 150, 1);
  font-size: 1rem;
}

.contentCard header {
  font-size: 2.3rem;
  color: rgba(232, 235, 247, 1);
  font-weight: bold;
  padding-bottom: 1rem;
}

.contentCard header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.whereYouHeaded {
  padding: 3rem 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  background: rgba(30, 35, 44, 0.7);
}

.whereYouHeaded header {
  color: #fff;
  font-size: 1.5rem;
  /* font-weight: bold; */
}

.whereYouHeaded header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.whereYouHeaded footer {
  color: rgba(244, 109, 37, 1);
  font-size: 0.7rem;
}

.whereYouHeadedCardContainer {
  display: flex;
  gap: 1.2rem;
  width: 100%;
  justify-content: center;
}

.whereYouHeadedCard {
  max-width: 14rem;
  flex: 1 1 2rem;
  /* min-width: 40rem; */
  flex-wrap: wrap;
  transform: translateY(0);
  transition: all 0.3s;
  cursor: pointer;
  background-color: #fff;
  padding: 0.5em;
  border-radius: 0.4rem;
}

.whereYouHeadedCard footer {
  text-align: center;
  color: #000;
  font-weight: bold;
}

.whereYouHeadedCard:hover {
  transform: translateY(-0.4rem);
}

.whereYouHeadedCard img {
  width: 100%;
  border-radius: 0.4rem;
  aspect-ratio: 1/0.5;
  object-fit: cover;
}

.hillStationContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  padding: 3rem 5rem;
}

.hillStationContainer header {
  color: #fff;
  font-size: 2.5rem !important;
  font-weight: 600;
}

.hillStationContainer header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hillStationContainer div {
  text-align: center;
  color: rgba(150, 150, 150, 1);
  font-size: 0.9rem;
}

.hillStationCardList {
  display: flex;
  gap: 1.4rem;
  flex-wrap: wrap;
}

.hillStationCard {
  flex: 1 1 20%;
  transition: all 0.5s;
  cursor: pointer;
  transform: translateY(0px);
  background: #fff;
  padding: 0.5em;
  border-radius: 0.5em;
}

.hillStationCard:hover {
  transform: translateY(-10px);
}

.hillStationCard img {
  width: 100%;
  aspect-ratio: 1/0.6;
  z-index: 10;
  /* height: 20rem; */
  object-fit: cover;
  border-radius: 4px 4px 0px 0px;
}

.hillStationCard footer {
  background: #fff;
  color: #000;
  padding: 0.3rem 0rem;
  border-radius: var(--footer-border-radius);
}

.thingsTodoContainer {
  padding: 2rem;
  display: flex;
  /* flex-direction: column; */
  gap: 2rem;
  /* background: rgba(30, 35, 44, 0.7); */
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  /* flex-direction: column; */
}

.thingsTodoContainer main {
  flex: 1 1 300px;
}

.thingsTodoContainer main header {
  font-size: clamp(2rem, 10vw, 4rem);
  color: #fff;
  font-weight: 600;
}

.bestPlacesToVisit div header,
.bestPlacesToVisitContent header {
  color: #fff;
  font-size: 4rem;
  font-weight: 600;
}

.thingsTodoContainer {
  display: flex;
  /* flex-wrap: wrap; */
}

.thingsTodoMain {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.thingsTodoBanner {
  /* flex: 1 1 30%; */
  min-width: 40%;
  aspect-ratio: 1/1;
}

.thingsTodoBanner img {
  width: 100%;
}

.thingsTodoList {
  margin-top: 2rem;
}

header:hover {
  /* text-decoration: underline; */
  cursor: pointer;
}

.thingsTodoContainer main header span,
.bestPlacesToVisit div header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bestPlacesToVisit div header {
  /* flex: 1 1 30%; */
  min-width: 40%;
}

.bestPlacesToVisitContent {
  display: flex;
  align-items: center !important;
  color: #fff;
  flex-wrap: wrap;
  gap: 2rem;
}

.bestPlacesToVisitContent div {
  flex: 1 1 300px;
}

.bestPlacesToStayImageGrid {
  display: flex;
  height: 30rem;
  gap: 1rem;
  /* flex-wrap: wrap; */
}

.bestPlacesToVisitContent div {
  flex: 1 1 auto;
}

.thingsTodoList {
  display: flex;
  flex-wrap: wrap;
}

.thingsTodoList li {
  list-style: none;
  flex: 1 1 50%;
  padding: 0.5em 0.5em 0.5em 0em;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.thingsTodoCardContainer {
  display: flex;
  gap: 2rem;
}

.thingsTodoCard {
  width: 100%;
  flex: 1 1 20%;
  cursor: pointer;
  transform: translateY(0);
  transition: all 0.5s;
  border-radius: 0.3rem;
}

.thingsTodoCard:hover {
  transform: translateY(-0.2rem);
}

.thingsTodoCard img {
  width: 100%;
  aspect-ratio: 1/0.6;
  border-radius: 0.3rem 0.3rem 0rem 0rem;
  object-fit: cover;
}

.thingsTodoCard footer {
  /* background-color: #fff; */
  color: rgba(244, 109, 37, 1);
  border-radius: var(--footer-border-radius);
  padding: 0.3rem 0rem;
}

.thingsTodoCard footer p {
  color: rgba(156, 156, 156, 1);
  font-size: 0.6rem;
}

.pilgrimsContainer {
  box-sizing: border-box;
  padding: 2rem 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background: rgba(30, 35, 44, 0.7);
}

.pilgrimsHeader {
  font-size: 2.5rem;
  color: #fff;
}

.pilgrimsHeader span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pilgrimsCardList {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
}

.pilgrimsCard {
  flex: 1 1 25%;
  transition: all 1s;
  border-radius: 0.3rem !important;
  cursor: pointer;
  /* box-shadow: 0rem 0rem 1rem #000; */
  aspect-ratio: 16/9;
  background: #fff;
  padding: 0.6em;
  border: 0.5em;
}

.pilgrimsCard img {
  width: 100%;
  aspect-ratio: 1/0.5;
  object-fit: fill;
  border-radius: 0.3rem 0.3rem 0rem 0rem;
}

.pilgrimsCard footer {
  background: #fff;
  color: #000;
  padding: 0.3rem 0rem;
  border-radius: var(--footer-border-radius);
  text-align: center;
  padding: 0.5rem 0rem;
}

.pilgrimsDescription {
  color: rgba(150, 150, 150, 1);
  padding: 0rem 3rem;
  text-align: center;
  font-size: 1.3rem;
}

.bestPlacesToVisit {
  display: flex;
  padding: 2rem 2rem;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  gap: 2rem;
}

.bestPlacesToVisit div header {
  color: #fff;
  font-size: 3.5rem;
  /* font-weight: bold; */
}

.bestPlacesToVisit header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bestPlacestoVisitCardContainer {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.bestPlacestoVisitCard {
  flex: 1 1 10rem;
  cursor: pointer;
  transform: translateY(0);
  transition: all 1s;
}
.bestPlacestoVisitCard img {
  width: 100%;
  aspect-ratio: 1/0.7;
  object-fit: cover;
}

.bestPlacestoVisitCard:hover {
  transform: translateY(-5px);
}

.bestPlacestoVisitCard footer {
  /* background-color: #fff; */
  color: rgba(244, 109, 37, 1);
  border-radius: var(--footer-border-radius);
  padding: 0.3rem 0rem;
}

.bestPlacestoVisitCard footer p {
  color: rgba(156, 156, 156, 1);
  font-size: 0.6rem;
}

.keyfactsContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  background-image: url("../../../../assets/illustrations//destination/keyFacts/Rectangle\ 14307.png");
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100% 100%;
}

.keyfactsContainer::after {
  content: "";
  position: absolute;
  inset: 0;
  backdrop-filter: blur(0.5rem);
}

.keyFactsArticle {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 2rem;
  padding: 7rem 2rem;
  z-index: 2 !important;
  filter: brightness(1) !important;
}

/* .keyFactsArticle::after {
  content: "";
  inset: 0;
  position: absolute;
  z-index: -1;

  
} */

.keyFactsArticle header,
.festivalsAndEvents header {
  color: #fff;
  font-size: 3rem;

  width: 100%;
  text-align: center;
  font-weight: 600;
  box-sizing: border-box;
}

.keyFactsArticle header span,
.sectionSectionOne header span,
.thingsToCarrySectionTwo header span,
.festivalsAndEvents header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.keyFactsCardContainer {
  display: flex;
  /* flex-wrap: wrap;
  padding: 4rem 5rem; */
  gap: 2rem;

  width: 100%;
  /* height: 100%; */
  box-sizing: border-box;
  justify-content: center;
}

@keyframes popUp {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.keyFactsCard {
  width: 12%;
  background: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  border-radius: 0.5em;
  animation: popUp 0.5s ease-out forwards;
  animation-delay: 10s;
}
.factHeader {
  font-size: 1rem;
}

.factContent {
  color: #f46d25;
  font-weight: 600;
}

.keyFactsSeeAll {
  width: 12%;

  display: flex;
  background: linear-gradient(
    to right,
    rgba(255, 243, 0, 1),
    rgba(244, 109, 37, 1)
  );
  color: #fff;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  animation: popUp 0.5s ease-out forwards;
  animation-delay: 10s;
}

.howToReachContent {
  padding: 5rem 2rem;
  box-sizing: border-box;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.sectionSectionOne {
  display: flex;
  flex-direction: column;
  color: #fff;
  gap: 2rem;
  flex: 1 1 55%;
  /* width: 40%; */
}

.sectionSectionTwo {
  display: flex;
  flex-direction: column;
  flex: 1 1 30%;
  /* width: 40%; */
}

.sectionSectionTwo > img {
  width: 100%;
}

.sectionSectionOne header,
.thingsToCarrySectionTwo header {
  color: #fff;
  font-size: 3rem;
  width: 100%;
  font-weight: 600;
  box-sizing: border-box;
}

.thingsToCarry {
  padding: 5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.thingsToCarrySectionOne {
  position: relative;
  z-index: 10;
}

.thingsToCarrySectionOne::after {
  content: "";
  inset: 6rem 0% 6rem -20%;
  background: #f46d25;
  position: absolute;
  filter: contrast(1);
  opacity: 0.1;
  z-index: -1;
  border-radius: 0% 46% 46% 0%;
}

@keyframes reposition {
  from {
    translate: var(--transformX) var(--transformY);
  }
  to {
    translate: 0 0;
  }
}

.bubbleOne {
  width: 3rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: #f46d25;
  top: 20%;
  opacity: 0.8;
  left: 70%;
  --transformX: 10%;
  --transformY: 30%;
}

.bubbleTwo {
  width: 2rem;
  opacity: 0.3;
  top: 0;
  left: 20%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: #f46d25;
  --transformX: 30%;
  --transformY: 60%;
}

.bubbleThree {
  width: 1rem;
  opacity: 0.5;
  top: 20%;
  left: 25%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: #f46d25;
  --transformX: 40%;
  --transformY: 70%;
}

.bubbleFour {
  width: 4rem;
  opacity: 0.1;
  top: 4rem;
  left: 40%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: #f46d25;
  --transformX: 40%;
  --transformY: 70%;
}

.bubbleOne,
.bubbleTwo,
.bubbleThree,
.bubbleFour {
  position: absolute;
  /* animation: reposition 5s ease-in infinite infinite; */
}

/* .thingsToCarrySectionOne::after {
  background: #f46d25;
  content: "";
  position: absolute;
  inset:0% 0%  10%;
} */

.thingsToCarrySectionTwo {
  width: 40%;
  color: #fff;
}

.thingsToCarryItems {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  list-style: none;
  padding: 0rem;
}

.thingsToCarryItems li {
  width: 38%;
}

.thingsToCarryCard img {
  width: 2rem;
}

.thingsToCarryCard p {
  font-size: 0.8rem;
}

.festivalsAndEvents {
  background-image: url("../../../../assets/illustrations//destination/festivals_and_events/image\ 1festivals_and_events.png");
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100% 100%;
  color: #fff;
  padding: 5rem 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.festivalsContent {
  text-align: center;
  width: 70%;
}

.footerVariantTwo {
  color: rgba(244, 109, 37, 1);
  text-align: left;
}

.festivalsCardContainer {
  display: flex;
  gap: 2rem;
}

.festivalCard {
  width: 15rem;
  background: #fff;
  padding: 0.5rem;
  align-self: flex-start;
  border-radius: 0.5rem;
}

.festivalCard img {
  width: 100%;
  aspect-ratio: 1.5/2.5;
  object-fit: cover;
  border-radius: inherit;
  /* border: 2px solid #fff; */
}

.shoppingAndEntertainment {
  padding: 5rem 2rem;
  color: #fff;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
}

.shoppingContentContainer {
  display: flex;

  justify-content: space-between;
}

.mapContainer {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.locationCard {
  background: #fff;
  color: #000;
  width: 16rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  position: relative;
}
.mapContainer {
  position: relative;
}

.locationCard:is(:nth-child(2)) {
  position: absolute;
  transform: translate(30%, -590%);
}

.locationCard:is(:nth-child(3)) {
  position: absolute;
  transform: translate(50%, 90%);
}

.locationCard:is(:nth-child(4)) {
  position: absolute;
  transform: translate(220%, 130%);
}

.locationCard:is(:nth-child(5)) {
  position: absolute;
  transform: translate(320%, 290%);
}

.locationCard img {
  width: 100%;
  aspect-ratio: 1/0.7;
}

.mapContainer img {
  width: 100%;
  aspect-ratio: 1/0.6;
}

.shoppingAndEntertainmentContent header {
  font-size: 2.5rem;
  color: #fff;
  font-weight: 600;
}

.shoppingAndEntertainmentContent header span {
  background-clip: text;
  background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shoppingAndEntertainmentContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 70%;
}

.footerVariantTwo p {
  color: #fff;
  margin: 0;
  font-size: 0.5rem;
}

.cardFlexContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0rem;
  gap: 2rem;
}

.cardVariantOne {
  flex: 1 1 auto;
  width: 20%;
  cursor: pointer;
  transform: translateY(0);
  transition: all 0.5s;
}

.tourPackages {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem;
  gap: 2rem;
  color: white;
}

.tourPackages header {
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
}

.tourPackages header span {
  background-clip: text;
  background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-size: 2rem; */
}

.tourPackagesContent {
  width: 100%;
  gap: 2rem;
  display: flex;
  justify-content: center;
}

.tourPackageCard {
  width: 15rem;
  background: #fff;
  padding: 0.75rem;
  border-radius: 0.5rem;
  align-self: flex-start;
  color: #000;
  cursor: pointer;
}

[data-currentPackage="true"] {
  background: linear-gradient(rgba(255, 243, 0, 1), rgba(244, 109, 37, 1));
  color: #fff;
  transform: scale(1.1);
  transition: all 250ms;
}

.tourPackageCard img {
  border-radius: 0.5rem;
  width: 100%;
}

.packageCardContent {
  display: flex;
  padding: 0rem 0.5rem;
  flex-direction: column;
  gap: 1rem;
}

.navigatorArrow :is(:first-child) {
  transform: translateX(0);
  transition: all 0.3s;
}

.navigatorArrow:hover :is(:first-child) {
  transform: translateX(2px);
  color: #f46d25;
}

.whereYouHeaded header,
.hillStationContainer header,
.pilgrimsContainer header,
.bestPlacesToVisit header {
  font-size: 1.5rem;
}

.hillStationDescription,
.pilgrimsDescription {
  font-size: 1rem !important;
}

@media (max-width: 768px) {
  .contentWithCarousel {
    flex-direction: column;
  }

  .topSectionHeader {
    font-size: 1.5rem !important;
  }

  .sliderContainer {
    width: 100%;
  }

  .hillStationContainer {
    padding: 1rem;
  }

  .whereYouHeaded {
    padding: 2.5rem 1rem 1.5rem 1rem;
    gap: 0.5rem;
  }
  .whereYouHeadedCardContainer {
    width: 95dvw;
    overflow: auto;
    padding: 1rem;

    box-sizing: border-box;
  }

  .whereYouHeaded header {
    font-size: 1.5rem;
  }

  .whereYouHeadedCard {
    min-width: 80%;
  }

  .whereYouHeadedCard img {
    width: 100% !important;
    /* aspect-ratio: 1/1; */
  }
  .whereYouHeadedCard footer {
    font-size: 1rem;
    text-align: center;
  }
  .hillStationContainer {
    gap: 0.5rem;
    padding: 2.5rem 1rem;
  }

  .hillStationCardList {
    display: flex;
    justify-content: center;
    padding: 1rem;
    width: 100% !important;
    box-sizing: border-box;
  }

  .hillStationCard {
    min-width: 80%;
  }

  .thingsTodoContainer,
  .pilgrimsContainer {
    padding: 1rem;
    box-sizing: border-box;
  }

  .thingsTodoCardContainer,
  .pilgrimsContainer {
    flex-wrap: nowrap;
    overflow: auto;
    width: 95dvw;
    padding: 1rem;
    box-sizing: border-box;
  }

  .thingsTodoContainer {
    gap: 0.5rem;
  }

  /* {
    font-size: 2rem !important;
  } */

  .thingsTodoCardContainer {
    padding: 0rem;
  }

  .pilgrimsContainer {
    width: 100%;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .pilgrimsContainer header {
    font-size: 1.5rem;
  }

  .pilgrimsCardList {
    width: 100%;
  }

  .thingsTodoCard {
    min-width: 80%;
  }
  .pilgrimsCard {
    min-width: 100%;
  }

  .pilgrimsDescription {
    padding: 1rem;
    text-align: left;
    padding: 0rem;
    text-wrap: balance;
    width: 100%;
    font-size: large;
  }

  .keyfactsContainer {
    display: none;
  }
  .tourPackages {
    display: none;
  }

  .cardFlexContainer {
    flex-wrap: nowrap;
    overflow: auto;
    box-sizing: border-box;
  }

  .cardVariantOne {
    min-width: 80%;
  }

  .bestPlacesToVisit {
    padding: 3rem 1rem;
    gap: 0.5rem;
  }

  .bestPlacestoVisitCardContainer {
    box-sizing: border-box;
    flex-wrap: nowrap;
    width: 95dvw;
    overflow: auto;
  }

  .bestPlacestoVisitCard {
    min-width: 80%;
  }

  .whereYouHeadedCardContainer,
  .thingsTodoCardContainer,
  .bestPlacestoVisitCardContainer,
  .cardFlexContainer,
  .hillStationCardList {
    scrollbar-width: none;
  }

  .hillStationContainer {
    align-items: flex-start;
  }
  .hillStationHeader {
    font-size: 1.25rem;
  }

  .hillStationDescription {
    text-align: left !important;
  }

  .whereYouHeaded header,
  .hillStationContainer header,
  .pilgrimsContainer header,
  .bestPlacesToVisit header {
    font-size: 1.25rem;
  }
}
